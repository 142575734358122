var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("CDataTable", {
    staticClass: "table-company-users",
    attrs: {
      striped: "",
      hover: "",
      items: _vm.loadedItems,
      fields: _vm.fields,
      loading: _vm.loading,
      noItemsView: {
        noResults: "No filtering results are available!",
        noItems: "No users found!",
      },
      "clickable-rows": "",
    },
    on: { "row-clicked": _vm.rowClicked },
    scopedSlots: _vm._u([
      {
        key: "role",
        fn: function ({ item }) {
          return [
            _c("td", [_c("strong", [_vm._v(_vm._s(item?.company_role))])]),
          ]
        },
      },
      {
        key: "id",
        fn: function ({ item }) {
          return [_c("td", [_vm._v(" #" + _vm._s(item?.id) + " ")])]
        },
      },
      {
        key: "image_icon",
        fn: function ({ item }) {
          return [
            _c(
              "td",
              [
                _c(
                  "CLink",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.imageClicked(item)
                      },
                    },
                  },
                  [
                    _c("CImg", {
                      staticClass: "c-avatar-img",
                      staticStyle: { width: "36px", height: "36px" },
                      attrs: {
                        src: item.image_icon || item.gravatar,
                        placeholderColor: "lightgray",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
      {
        key: "name",
        fn: function ({ item }) {
          return [_c("td", [_vm._v(" " + _vm._s(item.first_name) + " ")])]
        },
      },
      {
        key: "contact",
        fn: function ({ item }) {
          return [
            _c("td", [
              _c(
                "div",
                [
                  _c("CIcon", {
                    staticClass: "mr-1",
                    attrs: { name: "cil-at" },
                  }),
                  _vm._v(_vm._s(item.email)),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("CIcon", {
                    staticClass: "mr-1",
                    attrs: { name: "cil-phone" },
                  }),
                  _vm._v(_vm._s(item.mobile)),
                ],
                1
              ),
            ]),
          ]
        },
      },
      {
        key: "menutepay_verified_at",
        fn: function ({ item }) {
          return [
            _c("td", [
              item.menutepay_verified_at
                ? _c("span", [
                    _vm._v(
                      _vm._s(
                        new Date(item.menutepay_verified_at).toLocaleString()
                      )
                    ),
                  ])
                : _c(
                    "span",
                    [
                      _c(
                        "CLink",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.resendActivationEmail(item.id)
                            },
                          },
                        },
                        [_vm._v("Resend Activation Email")]
                      ),
                    ],
                    1
                  ),
            ]),
          ]
        },
      },
      {
        key: "email_verified_at",
        fn: function ({ item }) {
          return [
            _c("td", [
              item.email_verified_at
                ? _c("span", [
                    _vm._v(
                      _vm._s(new Date(item.email_verified_at).toLocaleString())
                    ),
                  ])
                : _c("span", [_vm._v("Not verified")]),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }