var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    [
      _c(
        "CCardHeader",
        [
          _c("CIcon", { attrs: { name: "cil-notes" } }),
          _c(
            "h5",
            { staticClass: "d-inline ml-2" },
            [
              _vm._v(" " + _vm._s(this.$route.meta.label) + " "),
              _vm.isEdit && !_vm.spinners.loading
                ? [
                    _vm._v(" : "),
                    _c(
                      "h3",
                      { staticClass: "d-inline" },
                      [
                        _c("CBadge", { attrs: { color: "secondary" } }, [
                          _vm._v(_vm._s(this.itemName)),
                        ]),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "card-header-actions" },
            [
              _c(
                "CButton",
                { attrs: { color: "dark", to: _vm.prevRoute } },
                [
                  _c("CIcon", {
                    staticClass: "align-bottom",
                    attrs: { name: "cil-arrow-thick-left" },
                  }),
                  _vm._v(" Back "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "CCardBody",
        [
          _c("alert-section", {
            attrs: {
              successAlertMessage: _vm.successAlertMessage,
              dismissSecs: _vm.dismissSecs,
              dismissSuccessAlert: _vm.dismissSuccessAlert,
              errorAlertMessage: _vm.errorAlertMessage,
              showErrorAlert: _vm.showErrorAlert,
            },
          }),
          !_vm.isValid
            ? _c("form-summary", {
                staticClass: "form-errors alert alert-danger",
                attrs: { validator: _vm.$v.form, attributes: _vm.attributes },
              })
            : _vm._e(),
          !_vm.spinners.loading
            ? _c(
                "CForm",
                [
                  _c(
                    "CTabs",
                    {
                      staticClass: "tab-menu",
                      attrs: {
                        "active-tab": _vm.activeTab,
                        variant: "pills",
                        vertical: {
                          navs: "col-xl-2 col-md-3",
                          content: "col-xl-10 col-md-9",
                        },
                      },
                      on: {
                        "update:activeTab": function ($event) {
                          _vm.activeTab = $event
                        },
                        "update:active-tab": function ($event) {
                          _vm.activeTab = $event
                        },
                      },
                    },
                    [
                      _c(
                        "CTab",
                        { attrs: { active: "" } },
                        [
                          _c(
                            "template",
                            { slot: "title" },
                            [
                              _c("CIcon", { attrs: { name: "cil-task" } }),
                              _vm._v(" General Information "),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mb-3 d-flex flex-column flex-md-row",
                              },
                              [
                                _c("CSelect", {
                                  staticClass: "w-100 mr-2",
                                  attrs: {
                                    label: "Country:",
                                    placeholder: "Please select..",
                                    lazy: false,
                                    options: _vm.authCountries,
                                    value: _vm.$v.form.countryId.$model,
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        _vm.$v.form.countryId,
                                        "$model",
                                        $event
                                      )
                                    },
                                  },
                                }),
                                _c("div", { staticClass: "w-100" }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mb-3 d-flex flex-column flex-md-row",
                              },
                              [
                                _c("CInput", {
                                  staticClass: "w-100 mr-2",
                                  attrs: {
                                    label: "Name:",
                                    placeholder: "Name",
                                    lazy: false,
                                    value: _vm.$v.form.name.$model,
                                    isValid: _vm.checkIfValid("name"),
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        _vm.$v.form.name,
                                        "$model",
                                        $event
                                      )
                                    },
                                  },
                                }),
                                _c("CInput", {
                                  staticClass: "w-100",
                                  attrs: {
                                    label: "VAT (BTW) Number:",
                                    placeholder: "VAT (BTW) Number",
                                    lazy: false,
                                    value: _vm.$v.form.vat.$model,
                                    disabled: _vm.invoiceCount > 0,
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        _vm.$v.form.vat,
                                        "$model",
                                        $event
                                      )
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "append",
                                        fn: function () {
                                          return [
                                            _c(
                                              "CButton",
                                              {
                                                attrs: {
                                                  color: "info",
                                                  disabled:
                                                    !_vm.$v.form.vat.$model ||
                                                    _vm.$v.form.vat.$model
                                                      .length === 0,
                                                },
                                                on: {
                                                  click: _vm.getCompanyInfo,
                                                },
                                              },
                                              [
                                                _vm.spinners.BtnGetCompanyInfo
                                                  ? _c("CSpinner", {
                                                      attrs: { size: "sm" },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" Get Company Info "),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    1132120910
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "section",
                              { staticClass: "address mb-3" },
                              [
                                _c("CInput", {
                                  staticClass: "w-100",
                                  attrs: {
                                    id: "verify_address",
                                    value: _vm.verified_address,
                                    label: "Verify Address From Google:",
                                    placeholder: "Enter a location",
                                    lazy: false,
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      _vm.verified_address = $event
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "prepend",
                                        fn: function () {
                                          return [
                                            _c(
                                              "CButton",
                                              { attrs: { color: "info" } },
                                              [
                                                _c("CIcon", {
                                                  attrs: {
                                                    name: "cil-location-pin",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    2480037663
                                  ),
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-column flex-md-row",
                                  },
                                  [
                                    _c("CTextarea", {
                                      staticClass: "w-100",
                                      attrs: {
                                        rows: "2",
                                        label: "Address:",
                                        placeholder:
                                          "Street name and house number",
                                        lazy: false,
                                        value: _vm.$v.form.address.$model,
                                        isValid: _vm.checkIfValid("address"),
                                      },
                                      on: {
                                        "update:value": function ($event) {
                                          return _vm.$set(
                                            _vm.$v.form.address,
                                            "$model",
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-column flex-md-row",
                                  },
                                  [
                                    _c("CInput", {
                                      staticClass: "w-100 mr-2",
                                      attrs: {
                                        id: "PostalCodeNum",
                                        type: "number",
                                        label: "Postal Code:",
                                        placeholder: "Zip or Postal Code",
                                        lazy: false,
                                        value: _vm.$v.form.postal_code.$model,
                                        isValid:
                                          _vm.checkIfValid("postal_code"),
                                      },
                                      on: {
                                        "update:value": function ($event) {
                                          return _vm.$set(
                                            _vm.$v.form.postal_code,
                                            "$model",
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                    _c("CInput", {
                                      staticClass: "w-100 mr-2",
                                      attrs: {
                                        label: "City:",
                                        placeholder: "City",
                                        lazy: false,
                                        value: _vm.$v.form.city.$model,
                                        isValid: _vm.checkIfValid("city"),
                                      },
                                      on: {
                                        "update:value": function ($event) {
                                          return _vm.$set(
                                            _vm.$v.form.city,
                                            "$model",
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mb-3 d-flex flex-column flex-md-row",
                              },
                              [
                                _c("CInput", {
                                  staticClass: "w-100",
                                  attrs: {
                                    label: "Monthly Limit:",
                                    placeholder: "0.00",
                                    lazy: false,
                                    value: _vm.$v.form.monthly_limit.$model,
                                    isValid: _vm.checkIfValid("monthly_limit"),
                                    type: "number",
                                    step: "100",
                                    pattern: "^\\d+(?:\\.\\d{1,2})?$",
                                    onblur:
                                      "(function(el){el.value=parseFloat(el.value).toFixed(2);})(this)",
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        _vm.$v.form.monthly_limit,
                                        "$model",
                                        $event
                                      )
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "prepend-content",
                                        fn: function () {
                                          return [
                                            _c("CIcon", {
                                              attrs: { name: "cil-euro" },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    586003441
                                  ),
                                }),
                                _c("div", { staticClass: "w-100" }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "form-group w-100" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "d-block",
                                    staticStyle: { "font-weight": "500" },
                                  },
                                  [_vm._v("Disabled:")]
                                ),
                                _c("CSwitch", {
                                  staticClass: "mt-020",
                                  attrs: {
                                    checked: _vm.$v.form.eol.$model,
                                    labelOn: "YES",
                                    labelOff: "NO",
                                    color: "success",
                                    shape: "pill",
                                    size: "lg",
                                    lazy: false,
                                  },
                                  on: {
                                    "update:checked": function ($event) {
                                      return _vm.$set(
                                        _vm.$v.form.eol,
                                        "$model",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        2
                      ),
                      _c(
                        "CTab",
                        [
                          _c("template", { slot: "title" }, [
                            _vm.isEdit
                              ? _c(
                                  "div",
                                  [
                                    _c("CIcon", {
                                      attrs: { name: "cil-group" },
                                    }),
                                    _vm._v(" Users"),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  [
                                    _c("CIcon", {
                                      attrs: { name: "cil-user" },
                                    }),
                                    _vm._v(" Manager"),
                                  ],
                                  1
                                ),
                          ]),
                          _vm.isEdit
                            ? _c("CompanyUsers", {
                                attrs: {
                                  active: _vm.activeTab == 1,
                                  companyId: _vm.itemId,
                                },
                              })
                            : _c(
                                "div",
                                { staticClass: "col-12 col-xl-6" },
                                [
                                  _c("CInput", {
                                    staticClass: "w-100",
                                    attrs: {
                                      label: "Name",
                                      placeholder: "Full name",
                                      value:
                                        _vm.$v.form.manager.first_name.$model,
                                      isValid:
                                        _vm.checkIfValid("manager.first_name"),
                                      autocomplete: "given-name",
                                    },
                                    on: {
                                      "update:value": function ($event) {
                                        return _vm.$set(
                                          _vm.$v.form.manager.first_name,
                                          "$model",
                                          $event
                                        )
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "prepend-content",
                                          fn: function () {
                                            return [
                                              _c("CIcon", {
                                                attrs: { name: "cil-user" },
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      3945887885
                                    ),
                                  }),
                                  _c("CInput", {
                                    staticClass: "w-100",
                                    attrs: {
                                      label: "Email",
                                      placeholder: "Email",
                                      value: _vm.$v.form.manager.email.$model,
                                      isValid:
                                        _vm.checkIfValid("manager.email"),
                                    },
                                    on: {
                                      "update:value": function ($event) {
                                        return _vm.$set(
                                          _vm.$v.form.manager.email,
                                          "$model",
                                          $event
                                        )
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "prepend-content",
                                          fn: function () {
                                            return [
                                              _c("CIcon", {
                                                attrs: { name: "cil-at" },
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      3652513961
                                    ),
                                  }),
                                  _c("CInput", {
                                    staticClass: "w-100",
                                    attrs: {
                                      label: "Mobile Number",
                                      placeholder: "Mobile number",
                                      value: _vm.$v.form.manager.mobile.$model,
                                      isValid:
                                        _vm.checkIfValid("manager.mobile"),
                                    },
                                    on: {
                                      "update:value": function ($event) {
                                        return _vm.$set(
                                          _vm.$v.form.manager.mobile,
                                          "$model",
                                          $event
                                        )
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "prepend-content",
                                          fn: function () {
                                            return [
                                              _c("CIcon", {
                                                attrs: { name: "cil-mobile" },
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      3328475100
                                    ),
                                  }),
                                ],
                                1
                              ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c("CSpinner", { attrs: { color: "primary" } }),
        ],
        1
      ),
      _c("CCardFooter", { staticClass: "sticky" }, [
        _c(
          "div",
          {
            staticClass: "d-flex flex-wrap align-items-center",
            staticStyle: { gap: "0.75rem" },
          },
          [
            _c(
              "CButton",
              {
                attrs: {
                  color: "primary",
                  disabled:
                    _vm.spinners.loading ||
                    _vm.spinners.BtnSubmit ||
                    _vm.spinners.BtnGetCompanyInfo,
                },
                on: {
                  click: function ($event) {
                    return _vm.submit()
                  },
                },
              },
              [
                _vm.spinners.BtnSubmit
                  ? _c("CSpinner", { attrs: { size: "sm" } })
                  : _vm._e(),
                _vm._v(" Submit "),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("mc-spinner", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.spinners.BtnSubmit,
            expression: "spinners.BtnSubmit",
          },
        ],
        attrs: { opacity: 0.8 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }